import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { withAuthenticator  } from '@aws-amplify/ui-react';
//import Chatbot from 'react-chatbot-kit';
//import ActionProvider from './WAIDActionProvider';
//import MessageParser from './WAIDMessageParser';
//import { createChatBotMessage } from "react-chatbot-kit";
import {
  Flex,
//  Heading,
//  Image,
Button,
  View,
} from "@aws-amplify/ui-react";
import { fetchAuthSession } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { createTodo, updateTodo, deleteTodo} from '../graphql/mutations';
import { listTodos, getTodo, todosByName } from '../graphql/queries';

import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ButtonMui from "@mui/material/Button";
import RemoveButtonBug from "./RemoveButtonBug";

import appConfig from '../AppConfig';

function Settings({signOut,user}) {

  const ver = appConfig.ver;
  
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [hist, setHist] = useState("");

  const [bugList, setBugList] = useState([]);

  const [open1, setOpen1] = useState(false);
  const handleToClose1 = () => {
      setOpen1(false);  
  };
  const handleClickToOpen1 = () => {
    setOpen1(true);
  };

  const [open2, setOpen2] = useState(false);
  const handleToClose2 = () => {
      setOpen2(false);  
  };
  const handleClickToOpen2 = () => {
    getBugs();
    setOpen2(true);
  };

  const [open3, setOpen3] = useState(false);
  const handleToClose3 = () => {
      setOpen3(false);  
  };
  const handleClickToOpen3 = () => {
    setOpen3(true);
  };

  const [open4, setOpen4] = useState(false);
  const handleToClose4 = () => {
      setOpen4(false);  
  };
  const handleClickToOpen4 = () => {
    setOpen4(true);
  };

  const [open5, setOpen5] = useState(false);
  const handleToClose5 = () => {
      setOpen5(false);  
  };
  const handleClickToOpen5 = () => {
    setOpen5(true);
  };


  const client = generateClient();


  useEffect(() => {
    document.title = ver;
    getUser();
    getHistDoc();
    }, []);
  

   async function getUser() {
   try {
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    //console.log(idToken.payload.email)
    setEmail(idToken.payload.email);
    //setHideChat(false)
  } catch (err) {
    console.log(err);
  }};
  



	const getBugs = async () => {


    try {
    
      const todos = await client.graphql({
        query: todosByName,
        variables: { name: 'bug' }
      });


     //console.log(todos);
     const todoList = todos.data.todosByName.items;
      setBugList(todoList);
     // console.log(todoList);

    } catch (e) {
      console.log('getBugs-settings failed: ', e);
  
    };
  
	}; 


  async function getHistDoc() {
    
    try {
      //Get user doc
      const oneTodo = await client.graphql({
      query: todosByName,
      variables: { name: user.username }
        });
        //console.log("SettingsDoc User:" + user.username);    
    //console.log(oneTodo);
      var tmpHist= oneTodo.data.todosByName.items[0].description;
      var tmp2 = tmpHist.replaceAll('{"messages":[',"");
      var tmp3 = tmp2.replaceAll('{"message":',"");
      var tmp4 = tmp3.replaceAll('"type":',"");
      var tmp5 = tmp4.replaceAll('"loading":false}',"");
      var tmp6 = tmp5.replaceAll('"id":'," ");
  setHist(tmp6);
      } catch (e) {
      console.log('Get User Settings Doc Failed: ', e);
    }
  };




function blank() {
  
};

function goBack() {
  navigate(`/`);
};


  return (
    <>


<div className="Headder"       
        >
          <span style={{ }}>
        {ver}
        </span>

 

        <span style={{paddingLeft:'1vw'}} >

        <span style={{ paddingRight:"4px",fontSize:"10px"}} >
 
        </span>
        
        
        


        <button className="HeadderButton" onClick={goBack}>Back</button>
        
        </span>


        </div>
   
    



<div className = "main2">

<div className = "title">
SETTINGS
</div>
<br></br>
<div className = "body2">

<Flex
  direction='column'
  //AlignItems='flex-start'
  //AlignContent='flex-start'
  //justifyContent='flex-start'
  textAlign='left'
>
  <span>
  Account E-Mail: {email}
  </span>
  
  <span>
  Operation Mode: STORE KIOSK 
  </span>
  <span>
  Strain Data Source: Test_Strain_DB_1280 <Button backgroundColor ="white" fontSize='10px' marginLeft='.35rem' height='20px' onClick={handleClickToOpen5}>Edit</Button>
  </span>
  <br></br>
  <span>
  AI Model: GPT4o <Button backgroundColor ="white" fontSize='10px' marginLeft='.35rem' height='20px' onClick={handleClickToOpen4}>Edit</Button>
  </span>
  <span>
  AI Personality: Herb Enthusiast <Button backgroundColor ="white" fontSize='10px' marginLeft='.35rem' height='20px' onClick={handleClickToOpen3}>Edit</Button>
  </span>
  <span>
  Allow Jokes: Yes
  </span>
  <span>
  Allow General Conversation: Yes
  </span>
<br></br>
<span>
Last Conversation: 
<Button backgroundColor ="white" fontSize='10px' marginLeft='.35rem' height='20px' onClick={handleClickToOpen1}>Show</Button>
</span>


<span>
Bug Reports: 
<Button backgroundColor ="white" fontSize='10px' marginLeft='.35rem' height='20px' onClick={handleClickToOpen2}>Show</Button>
</span>



</Flex>

</div>

<span>
  <br></br><br></br><br></br>
<Button backgroundColor ="darkred" color="white" fontSize='11px' paddingLeft='140px' paddingRight='140px' onClick={signOut}>Log Out</Button>
</span>    

  </div>





  <div stlye={{}}> 
      <Dialog open={open1} onClose={handleToClose1}>
        <DialogTitle>
          Last Conversation
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
          {hist}
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose1}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>



    <div stlye={{}}> 
      <Dialog open={open2} onClose={handleToClose2}>
        <DialogTitle>
          All Bug Reports
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
            

          
            {bugList.map((bug) => (

              <li className='' style={{	}} key={`bug${bug.id}`} >
                <span>{bug.id}</span><span>{bug.createdAt}</span> <br></br> <span>{bug.description}</span> 

                <RemoveButtonBug
                                    bugId={bug.id}
                                    funct={handleClickToOpen2} 
                                />

                <br></br><br></br>

              </li>

))}


         

          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose2}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>




    <div stlye={{}}> 
      <Dialog open={open3} onClose={handleToClose3}>
        <DialogTitle>
          Custom Bud-Tender Personality
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
            ...Under Construction...
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose3}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>



    <div stlye={{}}> 
      <Dialog open={open4} onClose={handleToClose4}>
        <DialogTitle>
          Select Current GPT Model 
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
            ...Under Construction...
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose4}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>


    <div stlye={{}}> 
      <Dialog open={open5} onClose={handleToClose5}>
        <DialogTitle>
          Select Current Data Source
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
            ...Under Construction...
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose5}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>


    </>
);

};

export default withAuthenticator(Settings);
