import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { withAuthenticator  } from '@aws-amplify/ui-react';
//import Chatbot from 'react-chatbot-kit';
//import ActionProvider from './WAIDActionProvider';
//import MessageParser from './WAIDMessageParser';
//import { createChatBotMessage } from "react-chatbot-kit";
import {
  Flex,
//  Heading,
//  Image,
Button,
  View,
} from "@aws-amplify/ui-react";
import { fetchAuthSession } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { createTodo, updateTodo, deleteTodo} from '../graphql/mutations';
import { listTodos, getTodo, todosByName } from '../graphql/queries';

import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ButtonMui from "@mui/material/Button";
import RemoveButtonStrain from "./RemoveButtonStrain";
import logo from '../logo.svg';
import appConfig from '../AppConfig';




function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
};


function Products({signOut,user}) {


 //AutoUpdate from backend
 var tmpcnt = 5000;
 useInterval(() => {
   getStrains();
   //console.log('fetch')
 }, tmpcnt);


  const ver = appConfig.ver;
  
  const [state1, setState1] = useState("");
  const [loading, setLoading] = useState("1");

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [hist, setHist] = useState("");

  const [strainList, setStrainList] = useState([]);

  const [open1, setOpen1] = useState(false);
  const handleToClose1 = () => {
      setOpen1(false);  
  };
  const handleClickToOpen1 = () => {
    setOpen1(true);
  };

  const [open2, setOpen2] = useState(false);
  const handleToClose2 = () => {
      setOpen2(false);  
  };
  const handleClickToOpen2 = () => {
      setOpen2(true);
  };

  const [open3, setOpen3] = useState(false);
  const handleToClose3 = () => {
      setOpen3(false);  
  };
  const handleClickToOpen3 = () => {
    setOpen3(true);
  };

  const [open4, setOpen4] = useState(false);
  const handleToClose4 = () => {
      setOpen4(false);  
  };
  const handleClickToOpen4 = () => {
    setOpen4(true);
  };


	
  const handleSubmit1 = (e) => {
    e.preventDefault();
    //👇🏻 calls the function
      addStrain();
      setLoading('1');
      handleToClose1();
};

const [comment1, setComment1] = useState("");
const [comment2, setComment2] = useState("");
const [comment3, setComment3] = useState("");
const [comment4, setComment4] = useState("");
const [comment5, setComment5] = useState("");
const [comment6, setComment6] = useState("");

  const client = generateClient();


  useEffect(() => {
    document.title = ver;
   // getUser();
    getStrains();
    }, []);
  

   async function getUser() {
   try {
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    //console.log(idToken.payload.email)
    setEmail(idToken.payload.email);
    //setHideChat(false)
  } catch (err) {
    console.log(err);
  }};
  

async function addStrain(){

  try {
    //add new strain
    console.log('creating new strain record')
        const result = await client.graphql({
          query: createTodo,
         variables: {
            input: {
             name: 'flower' ,
             description: comment1 ,
             type: comment2 ,
             effect: comment5 ,
             flavor: comment6,
             thc: comment3 ,
             cbd: comment4 ,
            }
          }
        });
        console.log('flower strain created');
        alert('Strain Added!');
        //Reset Form
        setComment1('');
        setComment2('');
        setComment3('');
        setComment4('');
        setComment5('');
        setComment6('');
      } catch (e) {
        console.log('Create new strain Failed: ', e);
      };

};



	const getStrains = async () => {

//console.log('loading strains')
    try {
    
      const todos = await client.graphql({
        query: todosByName,
        variables: { name: 'flower' }
      });


     //console.log(todos);
     const todoList = todos.data.todosByName.items;
      setStrainList(todoList);
   //   console.log(todoList);
      setLoading('0');
    } catch (e) {
      console.log('getStrains failed: ', e);
  
    };
  
	}; 


  async function getHistDoc() {
    
    try {
      //Get user doc
      const oneTodo = await client.graphql({
      query: todosByName,
      variables: { name: user.username }
        });
        //console.log("SettingsDoc User:" + user.username);    
    //console.log(oneTodo);
      var tmpHist= oneTodo.data.todosByName.items[0].description;
      var tmp2 = tmpHist.replaceAll('{"messages":[',"");
      var tmp3 = tmp2.replaceAll('{"message":',"");
      var tmp4 = tmp3.replaceAll('"type":',"");
      var tmp5 = tmp4.replaceAll('"loading":false}',"");
      var tmp6 = tmp5.replaceAll('"id":'," ");
  setHist(tmp6);
      } catch (e) {
      console.log('Get User Settings Doc Failed: ', e);
    }
  };




function blank() {
  
};

function goBack() {
  navigate(`/`);
};


if (loading === '1') { var tmpHideA = true; var tmpHideB = false } else { tmpHideA = false; tmpHideB = true};

  return (
    <>


<div className="Headder"       
        >
          <span style={{ }}>
        {ver}
        </span>

 

        <span style={{paddingLeft:'1vw'}} >

        <span style={{ paddingRight:"4px",fontSize:"10px"}} >
 
        </span>
        
        
        

        <button className="HeadderButton" onClick={handleClickToOpen1}>New Product</button>
        
        <button className="HeadderButton"onClick={goBack}>Back</button>
        
        </span>


        </div>
   
    



<div className = "main2">

<div className = "title">
STORE FLOWER STRAINS
</div>
<br></br>
<div hidden = {tmpHideA} className = "body2">

<Flex
  direction='column'
  //AlignItems='flex-start'
  //AlignContent='flex-start'
  //justifyContent='flex-start'
  textAlign='left'
>
  <span>
 
      {strainList.map((strain) => (

<li className='' style={{ 	}} key={`strain${strain.id}`} >
  <span style={{backgroundColor:'#6e6e6e', paddingLeft:'10px', paddingRight:'6px' 	}}  >{strain.description} - {strain.type} - {strain.thc} - {strain.cbd} - {strain.effect} - {strain.flavor} </span>

  <RemoveButtonStrain
                                    bugId={strain.id}
                                    funct={getStrains} 
                                />
                                
   </li>

))}

  </span>
</Flex>
</div>


<div hidden = {tmpHideB} className = "loadingPage">
  
  <img src={logo} className="App-logo" alt="logo" />

</div>




    

  </div>





  <div stlye={{}}> 
      <Dialog open={open1} onClose={handleToClose1}>
        <DialogTitle>
          New Flower Strain
        </DialogTitle>
        <DialogContent >
          <DialogContentText>        
      
          <form>

    <label for="comment1">Strain Name:</label>
    <input value={comment1} onChange={(e) => setComment1(e.target.value)} type='text' name='comment1' className='inp-field'/><br></br>

    <label for="comment2">Strain Type:</label>
    <input
        value={comment2}
        onChange={(e) => setComment2(e.target.value)}
        type='text'
        name='comment2'
        id='comment2'
        className='inp-field'
    />
    <br></br>

 <label for="comment3">THC%:</label>
    <input
        value={comment3}
        onChange={(e) => setComment3(e.target.value)}
        type='text'
        name='comment3'
        id='comment3'
        className='inp-field'
    />
<br></br>
<label for="comment4">CBD%:</label>
    <input
        value={comment4}
        onChange={(e) => setComment4(e.target.value)}
        type='text'
        name='comment4'
        id='comment4'
        className='inp-field'
    />
    <br></br>
    <label for="comment5">Main Effect:</label>
    <input
        value={comment5}
        onChange={(e) => setComment5(e.target.value)}
        type='text'
        name='comment5'
        id='comment5'
        className='inp-field'
    />
    <br></br>
    <label for="comment4">Main Flavor:</label>
    <input
        value={comment6}
        onChange={(e) => setComment6(e.target.value)}
        type='text'
        name='comment6'
        id='comment6'
        className='inp-field'
    />
</form>
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
        <ButtonMui onClick={handleSubmit1}>
            Save
          </ButtonMui> 
          <ButtonMui onClick={handleToClose1}>
            Cancel
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>



    <div stlye={{}}> 
      <Dialog open={open2} onClose={handleToClose2}>
        <DialogTitle>
          Remove Strain
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
          ...Under Construction...      
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose2}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>




    <div stlye={{}}> 
      <Dialog open={open3} onClose={handleToClose3}>
        <DialogTitle>
          Title3
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
            Text
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose3}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>



    <div stlye={{}}> 
      <Dialog open={open4} onClose={handleToClose4}>
        <DialogTitle>
          Title4
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
            Text
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose4}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>



    </>
);

};

export default withAuthenticator(Products);
