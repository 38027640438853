
class MessageParser {
    constructor(actionProvider, state) {
      this.actionProvider = actionProvider;
      this.state = state;
    }
  



        parse(message) {
       //     const lowerCaseMessage = message.toLowerCase()
       //     
        //    if (lowerCaseMessage.includes("hello")) {
        var tmpHist=(this.state);
              this.actionProvider.greet(message,tmpHist)
        //    }




    }
  }
  
  export default MessageParser;
  
