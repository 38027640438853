import React, { useEffect, useMemo, useState } from "react";
import 'react-chatbot-kit/build/main.css';
import './App.css';
import { Amplify } from 'aws-amplify';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "./styles.css";
import appConfig from './AppConfig';
//import {
//  Flex,
//  Heading,
//  Image,
//Button,
//  View,
//} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import Home from "./components/Home";
import Settings from "./components/Settings";
import Products from "./components/Products";
import Notfound from './components/Notfound';
Amplify.configure(awsExports);





function App({signOut,user}) {
  


     
     const ver = appConfig.ver;
     
     
       useEffect(() => {
         document.title = appConfig.ver;
        
         }, []);
       
     
        
     
       return (
         <div className="App">
     
     


     <BrowserRouter>
                <Routes>
                     <Route path='/' element={<Home />} />
                     <Route path='/settings' element={<Settings />} />
                     <Route path='/products' element={<Products />} />
                     <Route path="*" element={< Notfound />}></Route>
                </Routes>
            </BrowserRouter>
          
            



         </div>
     
     
     
       );
     }
     


//export default App;
export default withAuthenticator(App)
