import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { withAuthenticator  } from '@aws-amplify/ui-react';
import Chatbot from 'react-chatbot-kit';
import ActionProvider from './WAIDActionProvider';
import MessageParser from './WAIDMessageParser';
import { createChatBotMessage } from "react-chatbot-kit";
import { createTodo} from '../graphql/mutations';
import {
//  Flex,
//  Heading,
//  Image,
Button,
//  View,
} from "@aws-amplify/ui-react";

import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ButtonMui from "@mui/material/Button";

import { generateClient } from 'aws-amplify/api';
import { todosByName } from '../graphql/queries';
import appConfig from '../AppConfig';
import { Amplify } from 'aws-amplify';
import awsExports from '../aws-exports';
Amplify.configure(awsExports);




//function Home() {
function Home({signOut,user}) {
  const client = generateClient();
  const navigate = useNavigate();
  const ver = appConfig.ver;
  const [hist, setHist] = useState("");
  const [tmpOut, setTmpOut] = useState("");

  useEffect(() => {
    document.title = ver;
    getUserDoc();
    }, []);
  

  const [open1, setOpen1] = useState(false);
  const handleToClose1 = () => {
      setOpen1(false);  
  };
  const handleClickToOpen1 = () => {
    setOpen1(true);
  };

  const [open2, setOpen2] = useState(false);
  const handleToClose2 = () => {
      setOpen2(false);  
  };
  const handleClickToOpen2 = () => {
    setOpen2(true);
  };

  const [open3, setOpen3] = useState(false);
  const handleToClose3 = () => {
      setOpen3(false);  
  };
  const handleClickToOpen3 = () => {
    setOpen3(true);
  };

  const [open4, setOpen4] = useState(false);
  const handleToClose4 = () => {
      setOpen4(false);  
  };
  const handleClickToOpen4 = () => {
    setOpen4(true);
  };

  
  const [open5, setOpen5] = useState(false);
  const handleToClose5 = () => {
      setOpen5(false);  
  };
  const handleClickToOpen5 = () => {
    setOpen5(true);
  };


const config = { 
  botName: "Bud-Tender Bot",
 initialMessages: [  createChatBotMessage("Welcome! How may I help you?")],
   customStyles: {
     botMessageBox: {
       //backgroundColor: "#376B7E",
       backgroundColor: "#4c663b",
       minWidth: "700px",
     },
     chatButton: {
      //  /backgroundColor: "#376B7E",
       backgroundColor: "#4c663b",
     },
   },
 }
 


function blank() {
  
};

function goSettings() {
  navigate(`/settings`);
};

function goProducts() {
  navigate(`/products`);
};



async function getUserDoc() {
  


  try {
    //Get user doc
    const oneTodo = await client.graphql({
    query: todosByName,
    variables: { name: user.username }
      });
      console.log("SettingsDoc User(Home):" + user.username);    
  console.log(oneTodo);
 //   var tmpHist= oneTodo.data.todosByName.items[0].description;
 //     setHist(tmpHist);
 console.log('Loaded User Doc')
    } catch (e) {
    console.log('Get User Settings Doc Failed(Home): ', e);


    try {
      //get user doc2
          const oneTodo = await client.graphql({
          query: todosByName,
          variables: { name: user.username }
          });
          console.log("SettingsDoc User(Home2):" + user.username);    
          console.log(oneTodo);
       //   var tmpHist= oneTodo.data.todosByName.items[0].description;
       //   setHist(tmpHist);
      } catch (e) {
          console.log('Get User Settings Doc Failed(Home2): ', e);

          try {
      //Create new user
      console.log('creating new user record')
          const result = await client.graphql({
            query: createTodo,
           variables: {
              input: {
               name: user.username ,
              }
            }
          });
          console.log('user created');
        } catch (e) {
          console.log('Create new user Failed(Home): ', e);
        };


     };




  };

  
}


async function reportBug() {
  
   const input = prompt("Please enter a short bug description");
   if (input === null) { 
      //No Desc, Cancel
      alert("Bug report aborted");

   } else {
     //Do bug report
  

     try {
      //Get user doc
      const oneTodo = await client.graphql({
      query: todosByName,
      variables: { name: user.username }
        });
        console.log("SettingsDoc3 User(Home):" + user.username);    
    console.log(oneTodo);
      var tmpHist= oneTodo.data.todosByName.items[0].description;
        setHist(tmpHist);
   console.log('Loaded User Doc3')


//create new bug record
try {
  const result = await client.graphql({
     query: createTodo,
    variables: {
       input: {
        name: 'bug',
        description: "From: " + user.username + ' Desc: ' + input + ' Data: ' + tmpHist
       }
     }
   });
   alert("Your conversation history has been submitted as a Bug Report.  Thank you!");

   } catch (e) {
     console.log('Create Bug Record Failed(Home): ', e);
   };



      } catch (e) {
      console.log('Get User Settings Doc3 Failed(Home): ', e);
      };





  

  };


    };
    


    async function getBugs() {  

      try {
    
        const todos = await client.graphql({
          query: todosByName,
          variables: { name: 'bug' }
        });
       console.log(todos);
       setTmpOut(todos.data.todosByName.items[0].description);

      } catch (e) {
        console.log('getBugs failed: ', e);
    
      };
    
    
    
    };
    

  return (
    <>



<div className="Headder"       
        >
          <span style={{ }}>
        {ver}
        </span>

 

        <span style={{paddingLeft:'1vw'}} >

        <span style={{ paddingRight:"4px",fontSize:"11px"}} >
 
        </span>

        
        <button className="HeadderButton" onClick={goProducts}>Products</button>
        <button className="HeadderButton" onClick={reportBug}>Report Bug</button>
        <button className="HeadderButton"  onClick={goSettings}>Settings</button>
        
        
        </span>


        </div>


<div className = "main">



<div className = "body" style={{}}>
    <Chatbot config={config} actionProvider={ActionProvider} messageParser={MessageParser}  />
   {tmpOut}

    </div>
  
  </div>




    <div stlye={{}}> 
      <Dialog open={open1} onClose={handleToClose1}>
        <DialogTitle>
          Add New Strain
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
            ...under construction
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose1}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>



    <div stlye={{}}> 
      <Dialog open={open2} onClose={handleToClose2}>
        <DialogTitle>
          Remove Strain
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
          ...under construction
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose2}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>




    <div stlye={{}}> 
      <Dialog open={open3} onClose={handleToClose3}>
        <DialogTitle>
          Like Strain
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
          ...under construction
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose3}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>



    <div stlye={{}}> 
      <Dialog open={open4} onClose={handleToClose4}>
        <DialogTitle>
          Dislike Strain
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
          ...under construction
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose4}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>

    <div stlye={{}}> 
      <Dialog open={open5} onClose={handleToClose5}>
        <DialogTitle>
          All Strains
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
          ...under construction
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose5}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>




    </>
);

};

export default withAuthenticator(Home);
